import { lazy } from "react";
import { ModuleLayout } from "../Enums";

const Home = lazy(() => import("./Home"));
const MyPAE = lazy(() => import("./MyPAE"));
const Codes = lazy(() => import("./Codes"));

export const Pages = [
  {
    path: "/",
    component: Home,
    layout: ModuleLayout.WITH_HEADER,
  },
  {
    path: "/mypae",
    component: MyPAE,
    layout: ModuleLayout.WITH_HEADER,
  },
  {
    path: "/codes",
    component: Codes,
    layout: ModuleLayout.WITHOUT_HEADER,
  },
];
