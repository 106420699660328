import { Button } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { useNavigate } from "react-router-dom";
import { useResponsive } from "../../hooks/useResponsive";
import profileImage from "../../assets/images/andy-barnes.jpeg";
import awsImage from "../../assets/images/aws-certified-developer-associate.png";

const useStyles = makeStyles<{ isDesktop: boolean }>()(
  (theme, { isDesktop }) => ({
    root: {
      position: "sticky",
      top: 0,
      backgroundColor: "#fff",
      boxShadow: "0 0 10px rgba(100,100,100,0.3)",
      zIndex: theme.zIndex.appBar,
      img: {
        maxWidth: 128,
        maxHeight: 128,
        marginRight: theme.spacing(2),
        borderRadius: 96,
      },
      h1: {
        fontSize: "2rem",
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(0),
      },
      h3: {
        marginTop: 0,
        lineHeight: "1.3rem",
      },
    },
    container: {
      display: "flex",
      height: 128,
      width: isDesktop ? 1200 : "100%",
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    profile: {
      flex: 1,
      maxWidth: "100%",
      display: "flex",
      paddingLeft: isDesktop ? 0 : theme.spacing(1),
      paddingRight: isDesktop ? 0 : theme.spacing(1),
    },
    columnRight: {
      display: "flex",
      justifyContent: "flex-end",
      flex: 1,
    },
  })
);

type PageProps = {};
export const Header = ({}: PageProps) => {
  const { isDesktop } = useResponsive();
  const { classes } = useStyles({ isDesktop });
  const navigate = useNavigate();
  return (
    <header className={classes.root}>
      <section className={classes.container}>
        <div className={classes.profile}>
          <img src={profileImage} alt="Andy Barnes" />
          <div>
            <h1>Andy Barnes</h1>
            <h3>Senior Full Stack Software Developer</h3>
          </div>
        </div>
        <div>
          <a href="https://www.credly.com/badges/fd57cda4-ce43-434d-8a39-478d1188cf51/public_url">
            <img src={awsImage} alt="Andy Barnes - AWS Certification" />
          </a>
        </div>
      </section>
    </header>
  );
};

export default Header;
