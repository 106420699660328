export enum DocumentType {
  EMPTY = "",
  GEOHASH = "geohash",
  LOCATION = "location",
}

export enum QueryType {}

export enum CommandType {}

export enum DateTimeFormat {
  DEFAULT = "D ttt",
}

export enum ModuleLayout {
  WITH_HEADER = "WITH_HEADER",
  WITHOUT_HEADER = "WITHOUT_HEADER",
}

export enum AuthStatusType {
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
}

export enum ReportRowType {
  GROUP = "g",
  ITEM = "i",
}
