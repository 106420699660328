import { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import { makeStyles } from "@mui/styles";

import { ModuleLayout } from "./Enums";
import { Pages } from "./pages";
import { Header } from "./components/Header/Header";
// import { Footer } from "./components/Footer/Footer";
import { Loading } from "./components/UI/Loading";

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    display: "flex",
    flexDirection: "column",
    "& ::-webkit-scrollbar": {
      width: 4,
      height: 0,
      backgroundColor: "transparent",
    },
  },
}));

type RenderType = {
  page: any;
};
const Render = (props: RenderType) => {
  const { page } = props;
  const Component = page.component;
  if (page.layout === ModuleLayout.WITH_HEADER) {
    return (
      <>
        <Header />
        <Component />
      </>
    );
  }

  return <Component />;
};

export const Router = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Suspense fallback={<Loading />}>
        <Routes>
          {Pages.map((page) => {
            return (
              <Route
                path={page.path}
                element={<Render page={page} />}
                key={page.path}
              />
            );
          })}
        </Routes>
        {/* <Route component={Home} /> */}
      </Suspense>
    </div>
  );
};
