import { useDispatch, useSelector } from "react-redux";

import axios, { AxiosError, AxiosResponse } from "axios";
import toast from "react-hot-toast";

import { getJwt, getIdToken, getCompanyId } from "../store/authSlice";

type AxiosType = {
  children: any;
};
export const Axios = ({ children }: AxiosType) => {
  const jwt = useSelector(getJwt);

  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  if (jwt) {
    axios.defaults.headers.common["Authorization"] = jwt;
  }

  const errorHandler = async (error: AxiosError) => {
    if (axios.isCancel(error)) {
      return error;
    }
    console.error("error: ", error.stack);
    const errorJson: { status?: number } = error?.toJSON();
    if (errorJson.status === 401 || error.response?.status === 401) {
    } else if (error.response?.status === 403) {
    } else if (error.response) {
      // toast.error(`Error: ${error.response.data}`);
    } else if (error.request) {
      toast.error(`Error: Server Not Found`);
    } else if (!axios.isCancel(error)) {
      console.log("Error", error.message);
      // toast.error(`Error: ${error.message}`);
      toast.error("Error - please refresh your screen and try again");
    }
    return error;
  };

  const successHandler = (response: AxiosResponse) => {
    return response;
  };

  axios.interceptors.request.use(async (request) => {
    // if (request.data) {
    //   console.log("request.data: ", request.data);

    //   request.data.companyId = companyId;
    // }
    // console.log("request: ", request);

    return request;
  });

  axios.interceptors.response.use(
    (response: any) => successHandler(response),
    (error: any) => errorHandler(error)
  );

  return children;
};
